import { useQuery } from "@tanstack/react-query";

const useRedirectMicrosoft = () => {
  return useQuery({
    queryKey: ["saml-mso"],
    queryFn: async () => {
      const response = await fetch("/bff/auth/samlLogin/mso", {
        headers: {
          Accept: "*/*",
        },
      });
      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }
      const { redirectUrl } = await response.json();
      return redirectUrl;
    },
    keepPreviousData: true,
    onError: (err) => {
      console.error("Error loading redirect url: ", err);
    },
  });
};

export default useRedirectMicrosoft;
